import { Container, Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import React from "react";
import { getColorWithMode } from "../../constants/colors";
import { fetchAllReviewsForAdminPanel } from "../../utils";
import { Button, Link } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import ProductReviewCard from "../Reviews/ProductReviewCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const gearToUrlMap = {
  tent: "tent",
  sleepingPad: "sleeping-pad",
  backpack: "backpacks",
  sleepingBag: "sleeping-bag",
  insulatedJacket: "insulated-jacket",
};

const AdminContainer = ({ darkMode }) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const reviewData = await fetchAllReviewsForAdminPanel();
      if (!reviewData.error) {
        setData(reviewData);
      } else {
        setData([]);
      }
    })();
  }, []);

  return (
    <Container
      sx={{
        minWidth: "360px",
        width: "100%",
        maxWidth: "1440px",
        minHeight: "100vh",
        backgroundColor: getColorWithMode(darkMode, "base"),
        padding: "0px 0px 0px 0px",
        paddingBottom: "20px",
      }}
    >
      <Stack
        direction="row"
        width="100%"
        sx={{ padding: "5px 5px 5px 5px" }}
        alignItems={"center"}
        spacing={2}
      >
        <Typography>Tools: </Typography>
        <Link href="/admin/gearmanager">
          <Button variant="outlined">Gear Manager</Button>
        </Link>
        <Link href="/admin">
          <Button variant="outlined">Admin</Button>
        </Link>
      </Stack>
      {data ? (
        <Container>
          <Typography
            variant="h5"
            sx={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            All Reviews
          </Typography>
          <Stack spacing={2}>
            {data.map((review) => (
              <>
                <Typography>
                  <Link
                    target="_blank"
                    href={`/gear/${gearToUrlMap[review.gearType]}/${
                      review.itemId
                    }`}
                  >{`Review for: ${review.item.formattedBrand} ${review.item.name}`}</Link>
                </Typography>
                <ProductReviewCard
                  reviewValues={review}
                  gearType={review.gearType}
                />
              </>
            ))}
          </Stack>
        </Container>
      ) : (
        <Container sx={{ textAlign: "center" }}>
          You do not have permission to see this
        </Container>
      )}
    </Container>
  );
};

export default AdminContainer;
