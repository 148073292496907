import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Container } from "@mui/system";
import {
  FormControl,
  InputLabel,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { getUserIsLoggedIn, getUsersPackList, createNewPack } from "../utils";
import StarIcon from "@mui/icons-material/Star";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import { getColorWithMode } from "../constants/colors";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PackSelector = ({
  tableId,
  setTableId,
  setTableData,
  setPackName,
  packName,
  defaultPackId,
  setPackDescription,
  handleSelectNewPack,
  packIsLoading,
  setPackSettingsTags,
  setPackAnalytics,
  setPackVisibility,
  setAffiliateLinksAreOff,
}) => {
  const [userPackList, setUserPackList] = React.useState([]);
  const [packCreatedAlertIsOpen, setPackCreatedAlertIsOpen] =
    React.useState(false);

  useEffect(() => {
    (async () => {
      if (getUserIsLoggedIn()) {
        const packListResponse = await getUsersPackList();
        setUserPackList(packListResponse || []);
      }
    })();
  }, [tableId]);

  // Updates the name in the list to the new pack name
  useEffect(() => {
    const packListToModify = [...userPackList];
    for (let i = 0; i < packListToModify.length; i++) {
      if (packListToModify[i].id === tableId) {
        packListToModify[i].packName = packName;
      }
    }
    setUserPackList(packListToModify);
  }, [packName]);

  const handleSnackbarClose = () => {
    setPackCreatedAlertIsOpen(false);
  };

  const handleCreateNewPack = async () => {
    if (getUserIsLoggedIn()) {
      const {
        packName,
        tableId,
        tableData,
        packDescription,
        isPublic,
        tags,
        analytics,
        hasAffiliateLinksTurnedOff,
      } = await createNewPack();
      setTableId(tableId);
      setPackName(packName);
      setTableData(tableData);
      setPackDescription(packDescription);
      setPackCreatedAlertIsOpen(true);
      setPackSettingsTags(tags);
      setPackVisibility(isPublic);
      setPackAnalytics(analytics || {});
      setAffiliateLinksAreOff(hasAffiliateLinksTurnedOff || false);
    }
  };

  const userIsLoggedIn = getUserIsLoggedIn();

  return (
    <Stack gap={2} sx={{ width: "100%" }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">
          {userIsLoggedIn ? "My Saved Packs" : "Login to Save Packs"}
        </InputLabel>
        <Select
          key={tableId}
          value={tableId}
          labelId="demo-simple-select-label"
          label={userIsLoggedIn ? "My Saved Packs" : "Login to Save Packs"}
          disabled={!userPackList.length}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
            },
            transformOrigin: { horizontal: "left" },
            style: {
              maxHeight: 400,
            },
          }}
          onChange={(e) => {
            handleSelectNewPack(e.target.value);
          }}
        >
          {userPackList.map(({ packName, id, isDefault }) => (
            <MenuItem value={id}>
              {isDefault && id !== tableId ? (
                <>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary={packName} />
                </>
              ) : packIsLoading && tableId ? (
                <ListItemIcon>
                  <CircularProgress size={18} />
                </ListItemIcon>
              ) : (
                packName
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="success"
        disabled={!userIsLoggedIn}
        onClick={handleCreateNewPack}
      >
        Create New Pack
      </Button>
      <Snackbar
        open={packCreatedAlertIsOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "250px", color: "white" }}
        >
          New pack created
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default PackSelector;
