import React from "react";

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FILTER_CONFIG, {
  FILTER_VALUE_TYPES,
  COMPARATOR_TYPES,
} from "../../utils/articles/filterConfig";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { fetchBrandsForGearFilter } from "../../utils";

const getComparatorDropdown = (filter, handleComparatorUpdate, index) => {
  if (filter.valueType === FILTER_VALUE_TYPES.NUMBER) {
    return (
      <Select
        onChange={(e) => {
          handleComparatorUpdate(e.target.value, index);
        }}
        value={filter.comparator}
        size="small"
        sx={{ width: "180px" }}
      >
        {Object.keys(COMPARATOR_TYPES).map((key) => (
          <MenuItem value={key}>{key}</MenuItem>
        ))}
      </Select>
    );
  }
};

const getValueDropdown = (filter, handleUpdate, index, options) => {
  console.log(options);
  if (filter.valueType === FILTER_VALUE_TYPES.NUMBER) {
    return (
      <TextField
        size="small"
        InputLabelProps={{ shrink: true }}
        type="number"
        value={filter.value}
        onChange={(e) => {
          handleUpdate(e.target.value, index);
        }}
        sx={{ width: "100px" }}
      />
    );
  } else if (filter.valueType === FILTER_VALUE_TYPES.BRAND) {
    return (
      <Select
        size="small"
        InputLabelProps={{ shrink: true }}
        value={filter.value}
        onChange={(e) => {
          handleUpdate(e.target.value, index);
        }}
        sx={{ width: "260px" }}
      >
        {options.brands.map((brand) => (
          <MenuItem key={brand} value={brand}>
            {brand}
          </MenuItem>
        ))}
      </Select>
    );
  } else if (filter.valueType === FILTER_VALUE_TYPES.SELECT) {
    return (
      <Select
        size="small"
        InputLabelProps={{ shrink: true }}
        value={filter.value}
        onChange={(e) => {
          handleUpdate(e.target.value, index);
        }}
        sx={{ width: "260px" }}
      >
        {filter.selectionValues.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    );
  }
};

const ArticleGearFilter = ({ key, value, onChange, gearType }) => {
  const [selectedFilters, setSelectedFilters] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [brands, setBrands] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (gearType) {
        const brands = await fetchBrandsForGearFilter(gearType);
        if (brands) {
          setBrands(brands);
        }
      }
    })();
  }, [gearType]);

  if (!gearType) return <></>;

  const handleOpenAddFilter = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseAddFilter = () => {
    setAnchorEl(null);
  };

  const filterConfig = FILTER_CONFIG[gearType];

  const handleUpdateOverallModule = () => {
    onChange(selectedFilters);
  };

  const handleAddFilter = (event) => {
    const filterToAdd = JSON.parse(
      JSON.stringify(filterConfig[event.target.value])
    );
    setSelectedFilters([...selectedFilters, filterToAdd]);
    handleCloseAddFilter();
    handleUpdateOverallModule();
  };

  const handleComparatorUpdate = (value, index) => {
    const newFilters = [...selectedFilters];
    newFilters[index].comparator = value;
    setSelectedFilters(newFilters);
    handleUpdateOverallModule();
  };

  const handleValueUpdate = (value, index) => {
    const newFilters = [...selectedFilters];
    newFilters[index].value = value;
    setSelectedFilters(newFilters);
    handleUpdateOverallModule();
  };

  return (
    <Box>
      {selectedFilters.map((filter, index) => (
        <>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography sx={{ width: "80px" }}>{filter.label} is </Typography>
            {getComparatorDropdown(filter, handleComparatorUpdate, index)}{" "}
            {getValueDropdown(filter, handleValueUpdate, index, { brands })}
          </Stack>
          {index < selectedFilters.length - 1 ? (
            <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
              and
            </Typography>
          ) : (
            <></>
          )}
        </>
      ))}
      <Button
        disableElevation
        onClick={handleOpenAddFilter}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ width: "150px" }}
      >
        Add filter
      </Button>
      <Menu
        size="small"
        label="Add a filter"
        fullWidth
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseAddFilter}
        InputLabelProps={{ shrink: true }}
      >
        {filterConfig.map(({ label }, index) => (
          <MenuItem value={index} onClick={handleAddFilter}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ArticleGearFilter;
