import GEAR_TYPES from "../../constants/gearTypes.json";

const FILTER_TYPES = {
  WEIGHT: "WEIGHT",
  PRICE: "PRICE",
  BRAND: "BRAND",
  SETUP: "SETUP",
  SLEEPS: "SLEEPS",
};

export const FILTER_VALUE_TYPES = {
  NUMBER: "NUMBER",
  SELECT: "SELECT",
  BRAND: "BRAND",
};

export const COMPARATOR_TYPES = {
  LESS_THAN: "LESS_THAN",
  GREATER_THAN: "GREATER_THAN",
  EQUAL_TO: "EQUAL_TO",
};

const ALL_FILTERS = [
  {
    label: "weight",
    type: FILTER_TYPES.WEIGHT,
    valueType: FILTER_VALUE_TYPES.NUMBER,
    comparator: COMPARATOR_TYPES.LESS_THAN,
    value: null,
  },
  {
    label: "price",
    type: FILTER_TYPES.PRICE,
    valueType: FILTER_VALUE_TYPES.NUMBER,
    comparator: COMPARATOR_TYPES.LESS_THAN,
    value: null,
  },
  {
    label: "brand",
    type: FILTER_TYPES.BRAND,
    valueType: FILTER_VALUE_TYPES.BRAND,
    comparator: COMPARATOR_TYPES.EQUAL_TO,
    value: null,
  },
];

const TENT_SPECS = [
  {
    label: "Setup",
    type: FILTER_TYPES.SETUP,
    valueType: FILTER_VALUE_TYPES.SELECT,
    comparator: COMPARATOR_TYPES.EQUAL_TO,
    selectionValues: [
      "Freestanding",
      "Pyramid",
      "Semi-Freestanding",
      "Trekking Pole",
    ],
    value: null,
  },
  {
    label: "Sleeps",
    type: FILTER_TYPES.SLEEPS,
    valueType: FILTER_VALUE_TYPES.SELECT,
    comparator: COMPARATOR_TYPES.EQUAL_TO,
    selectionValues: ["1", "2", "3", "4"],
    value: null,
  },
];

const filterGearMap = {
  [GEAR_TYPES.SLEEPING_PAD]: [...ALL_FILTERS],
  [GEAR_TYPES.SLEEPING_BAG]: [...ALL_FILTERS],
  [GEAR_TYPES.TENT]: [...ALL_FILTERS, ...TENT_SPECS],
  [GEAR_TYPES.BACKPACK]: [...ALL_FILTERS],
  [GEAR_TYPES.INSULATED_JACKET]: [...ALL_FILTERS],
};

export default filterGearMap;
