import React from "react";
import { Container, Stack } from "@mui/system";
import { getColorWithMode, STYLISH_DARK } from "../constants/colors";
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { getUserIsLoggedIn, submitMessage } from "../utils";
import { isValidEmail } from "../utils/validationUtils";
import CheckIcon from "@mui/icons-material/Check";

const contactTypes = {
  FEEDBACK: "FEEDBACK",
  QUESTION: "QUESTION",
  COMMENT: "COMMENT",
  CONCERN: "CONCERN",
  OTHER: "OTHER",
  FEATURE_REQUEST: "FEATURE_REQUEST",
};

const contactTypeLabel = {
  [contactTypes.FEEDBACK]: "Feedback",
  [contactTypes.QUESTION]: "Question",
  [contactTypes.COMMENT]: "Comment",
  [contactTypes.CONCERN]: "Concern",
  [contactTypes.FEATURE_REQUEST]: "Feature Request",
  [contactTypes.OTHER]: "Other",
};

const ContactContainer = ({
  setMainAlertIsOpen,
  setMainAlertDetails,
  isDarkMode,
}) => {
  const [contactForm, setContactForm] = React.useState({
    email: "",
    text: "",
    type: contactTypes.FEEDBACK,
    responseRequired: false,
  });
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidText, setInvalidText] = React.useState(false);
  const [wasSubmitted, setWasSubmitted] = React.useState(false);

  const handleSubmit = async () => {
    if (contactForm.text === "") {
      setInvalidText(true);
      return;
    }
    if (contactForm.responseRequired && !getUserIsLoggedIn()) {
      if (!isValidEmail(contactForm.email)) {
        setInvalidEmail(true);
        return;
      }
    }
    await submitMessage(contactForm);
    setWasSubmitted(true);
    setMainAlertDetails({
      type: "success",
      message: "Message sent Successfully",
    });
    setMainAlertIsOpen(true);
  };

  return (
    <Container
      fixed
      style={{
        backgroundColor: getColorWithMode(isDarkMode, "base"),
        float: "right",
        height: "calc(100vh - 50px)",
        minHeight: "920px",
        width: "1152px",
        maxWidth: "1200px",
        marginLeft: "0px",
        paddingLeft: "0px",
      }}
    >
      <Container sx={{ maxHeight: "calc(100vh - 100px)" }}>
        <Stack sx={{ marginTop: "50px", marginLeft: "20px" }}>
          <Typography variant="h4">Contact Us</Typography>
          <Typography variant="subtitle" sx={{ marginTop: "5px" }}>
            Questions, comments, feature requests, feedback or concerns? Please
            don't hesitate to reach out.
          </Typography>
          <FormControl sx={{ width: "200px", marginTop: "20px" }} size="small">
            <InputLabel>Reason for Contact</InputLabel>
            <Select
              value={contactForm.type}
              label="Reason for Contact"
              onChange={(event) => {
                setContactForm({ ...contactForm, type: event.target.value });
              }}
            >
              <MenuItem value={contactTypes.FEEDBACK}>
                {contactTypeLabel[contactTypes.FEEDBACK]}
              </MenuItem>
              <MenuItem value={contactTypes.QUESTION}>
                {contactTypeLabel[contactTypes.QUESTION]}
              </MenuItem>
              <MenuItem value={contactTypes.COMMENT}>
                {contactTypeLabel[contactTypes.COMMENT]}
              </MenuItem>
              <MenuItem value={contactTypes.CONCERN}>
                {contactTypeLabel[contactTypes.CONCERN]}
              </MenuItem>
              <MenuItem value={contactTypes.FEATURE_REQUEST}>
                {contactTypeLabel[contactTypes.FEATURE_REQUEST]}
              </MenuItem>
              <MenuItem value={contactTypes.OTHER}>
                {contactTypeLabel[contactTypes.OTHER]}
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            error={invalidText}
            rows={5}
            InputProps={{ maxLength: 1028 }}
            sx={{ width: "640px", marginTop: "10px" }}
            placeholder="Enter your message here..."
            value={contactForm.text}
            multiline
            onChange={(e) => {
              setInvalidText(false);
              setWasSubmitted(false);
              setContactForm({ ...contactForm, text: e.target.value });
            }}
          ></TextField>
          <FormControl sx={{ width: "250px", marginLeft: "5px" }}>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={contactForm.responseRequired}
                  sx={{ width: "40px" }}
                  onChange={() => {
                    setContactForm({
                      ...contactForm,
                      responseRequired: !contactForm.responseRequired,
                    });
                    setInvalidEmail(false);
                  }}
                />
              }
              label="Response Requested?"
              labelPlacement="end"
            />
          </FormControl>
          {getUserIsLoggedIn() ? (
            <></>
          ) : (
            <FormControl sx={{ marginTop: "10px" }}>
              <TextField
                size="small"
                error={invalidEmail}
                required={contactForm.responseRequired}
                label="Email"
                placeholder="Only required if you'd like a response"
                style={{ width: "640px" }}
                onChange={(e) => {
                  setInvalidEmail(false);
                  setContactForm({ ...contactForm, email: e.target.value });
                }}
              />
              {invalidEmail ? (
                <FormHelperText
                  style={{ width: "640px" }}
                  id="component-error-text"
                >
                  A valid email must be provided when a response is requested.
                </FormHelperText>
              ) : (
                <></>
              )}
            </FormControl>
          )}
          {wasSubmitted ? (
            <Button
              variant={isDarkMode ? "outlined" : "contained"}
              sx={{ width: "200px", marginTop: "5px" }}
              disabled
              startIcon={<CheckIcon />}
            >
              Message Sent
            </Button>
          ) : (
            <Button
              variant={isDarkMode ? "outlined" : "contained"}
              sx={{ width: "200px", marginTop: "5px" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
          <Typography
            variant="subtitle2"
            sx={{ marginTop: "5px", opacity: 0.7 }}
          >
            Prefer to reach out directly? Feel free to email me directly at
            thomas@packwizard.com
          </Typography>
        </Stack>
      </Container>
    </Container>
  );
};

export default ContactContainer;
